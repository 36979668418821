export default {
  common: {
    field: {
      email: 'Email',
      emailPlaceholder: 'Informe seu email...',
      password: 'Senha',
      passwordPlaceholder: 'Informe sua senha...',
      passwordHelp: 'Pelo menos 8 caracteres, 1 número, LETRAS MAIÚSCULAS, e caracteres especiais (!@#$%...) são necessários.',
      fullName: 'Nome completo',
      fullNamePlaceholder: 'Informe seu nome...',
      code: 'Código',
      previewOnly: 'Visualização apenas 👀',
      typeYourAnswer: 'Digite sua resposta aqui...',
      press: 'pressione',
      enter: 'Enter ↵',
      sayHiHere: 'Diga oi aqui!',
      phone: 'Telefone',
      phonePlaceholder: 'Informe seu telefone...',
      searchPlaceholder: 'Pesquisar País...',
      apiToken: 'Token de API'
    },
    action: {
      signin: 'Entrar',
      signup: 'Criar conta',
      confirmCode: 'Confirmar código',
      signinWith: 'Usar login do',
      signupWith: 'Criar conta usando',
      close: 'Fechar',
      save: 'Salvar',
      cancel: 'Cancelar',
      delete: 'Excluir',
      edit: 'Editar',
      add: 'Adicionar',
      remove: 'Remover',
      copy: 'Copiar',
      copyToClipboard: 'Copiar para área de transferência',
      yes: 'Sim',
      no: 'Não',
      ok: 'Ok',
      next: 'Próximo',
      back: 'Voltar',
      done: 'Concluído',
      continue: 'Continuar'
    },
    instruction: {
      pleaseWait: 'Por favor, aguarde...',
      importing: 'Importando...',
      loading: 'Carregando...',
      redirectFailed: 'Ops, não conseguimos redirecionar neste momento. Por favor, tente novamente em alguns instantes.',
      permission: 'Parece que você não nos deu a permissão correta. Por favor, feche esta janela e inicie o processo novamente, verificando se você selecionou a permissão correta.'
    },
    feedback: {
      copySuccess: 'Copiado para a área de transferência com sucesso!',
      copyError: 'Seu navegador não suporta a funcionalidade de copiar para a área de transferência.'
    },
    date: {
      now: 'agora',
      past: 'há {0}',
      future: 'em {0}',
      secondOne: 'um segundo',
      secondOther: '{0} segundos',
      minuteOne: 'um minuto',
      minuteOther: '{0} minutos',
      hourOne: 'uma hora',
      hourOther: '{0} horas',
      dayOne: 'um dia',
      dayOther: '{0} dias',
      weekOne: 'uma semana',
      weekOther: '{0} semanas',
      monthOne: 'um mês',
      monthOther: '{0} meses',
      yearOne: 'um ano',
      yearOther: '{0} anos',
      hoursAgo: 'há {n} horas',
      hourAgo: 'há 1 hora',
      minutesAgo: 'há {n} minutos',
      minuteAgo: 'há 1 minuto',
      justNow: 'agora mesmo',
      yesterday: 'ontem',
      daysAgo: 'há {n} dias',
      dayAgo: 'há 1 dia',
      monthsAgo: 'há {n} meses',
      monthAgo: 'há 1 mês',
      yearsAgo: 'há {n} anos',
      yearAgo: 'há 1 ano',
      second: 'segundo',
      seconds: 'segundos',
      minute: 'minuto',
      minutes: 'minutos',
      hour: 'hora',
      hours: 'horas',
      day: 'dia',
      days: 'dias',
      week: 'semana',
      weeks: 'semanas',
      month: 'mês',
      months: 'meses',
      year: 'ano',
      years: 'anos'
    },
    user: 'usuário | usuários',
    justYou: 'Apenas você',
    plans: {
      free: 'Plano Free',
      trial: 'Plano Trial',
      starter: 'Plano Starter',
      plus: 'Plano Plus',
      professional: 'Plano Professional'
    },
    roles: {
      owner: 'Proprietário',
      invited: 'Convidado'
    }
  },
  auth: {
    acceptInvitation: {
      accepting: 'Aceitando o convite...',
      error: {
        wasNotPossibleAccept: 'Ooops, não foi possível aceitar o convite no momento. Por favor, tente novamente em alguns instantes.',
        checkTheLink: 'Convite não encontrado. Por favor, verifique o link e tente novamente.',
        invitationExpired: 'Convite expirado. Por favor, solicite um novo convite.',
        invitationAlreadyAccepted: 'Convite já aceito. Por favor, faça o login.'
      }
    },
    signin: {
      title: 'Bom ver você 👋',
      signup: 'Crie uma conta',
      footer: 'Não tem conta no Yay! Forms ainda?',
      forgotPassword: 'Esqueceu sua senha?'
    },
    signup: {
      title: 'Crie sua conta 🫶',
      footer: 'Já tem uma conta no Yay! Forms?'
    },
    confirmation: {
      title: 'Confirme sua identidade 🪪',
      instructions: 'Você deve ter recebido um código no seu email (veja também a pasta <b>spam</b>). Por favor, informe o código abaixo para concluir.',
      footer: 'Não recebeu o código?',
      sendAnotherCode: 'Enviar outro código',
      resendCodeFeedback: 'Enviamos outro código. Por favor, aguarde alguns segundos e verifique a sua pasta de spam também.'
    },
    forgotPassword: {
      title: 'Recupere sua senha 🔑',
      sendResetInstructions: 'Enviar instruções',
      sendResetFeedback: 'Ótimo! Por favor, veja as instruções de recuperação de senha enviadas para o seu email.',
      footer: 'Ir para o <a href="/auth/signin" class="font-bold text-link">login</a>'
    },
    createPassword: {
      title: 'Crie sua senha 🔑',
      sendCreateInstructions: 'Enviar instruções de criação',
      sendCreateFeedback: 'Ótimo! Por favor, veja as instruções de criação de senha enviadas para o seu email.',
      footer: 'Ir para o <a href="/auth/signin" class="font-bold text-link">login</a>'
    },
    resetPassword: {
      title: 'Defina sua nova senha',
      saveNewPassword: 'Salvar nova senha',
      passwordUpdatedFeedback: 'Senha atualizada com sucesso!'
    }
  },
  billing: {
    cancel: {
      redirectingToDashBoard: 'Redirecionando para o dashboard...'
    },
    checkout: {
      redirectingToCheckout: 'Redirecionando para o checkout...'
    },
    paymentMethods: {
      redirectingToPaymentMethods: 'Redirecionando para os métodos de pagamento...'
    },
    portal: {
      redirectingToBillingPortal: 'Redirecionando para o portal de faturamento...'
    },
    pricing: {
      title: 'Selecione o seu novo plano',
      yourPlan: 'Seu plano atual',
      per: 'por',
      pay: 'Pagar',
      monthly: 'Mensalmente',
      yearly: 'Anualmente',
      freeTrial: 'Iniciar período de teste gratuito',
      month: 'Mês',
      year: 'Ano',
      error: {
        pricingInformation: 'Ops, não conseguimos carregar as informações de preços neste momento. Por favor, tente novamente em alguns instantes.',
        affiliateInformation: 'Ops, não conseguimos carregar as informações de afiliados neste momento. Por favor, tente novamente em alguns instantes.'
      }
    },
    plans: {
      singleProfessional: '<b>20k</b> respostas/mensais',
      singlePlus: '<b>2k</b> respostas/mensais',
      singleStarter: '<b>200</b> respostas/mensais',
      singleFree: '<b>20</b> respostas/mensais',
      unlimited: 'Formulários ilimitados',
      customEndingScreens: 'Telas de encerramento personalizadas',
      automaticKeyword: 'Extração automática de palavras-chave',
      sentimentDetection: 'Detecção de sentimento',
      emotionDetection: 'Detecção de emoção'
    },
    success: {
      title: 'Sua assinatura foi ativada com sucesso. Redirecionando para o painel...'
    }
  },
  forms: {
    index: {
      edit: 'Para editar este formulário, por favor abra-o em uma tela maior.'
    },
    share: {
      isNotPublishedYet: 'Este formulário ainda não foi publicado. Ele ficará visível para os visitantes assim que você o publicar',
      tooltipPublic: 'Salvar alterações e tornar este formulário público',
      publish: 'Publicar',
      share: 'Compartilhe seu formulário',
      link: 'Este é o link para o seu formulário',
      tooltipCopy: 'Copiar para a área de transferência',
      copy: 'Copiar',
      tooltipQrCode: 'Gerar QR code',
      preview: 'Preview',
      tooltipPreview: 'Pré-visualizar seu formulário',
      embed: 'Incorporar em seu site',
      userTracking: 'Rastreamento de usuário',
      embedCard: {
        wordpress: 'Baixar o plugin do Yay! Forms para Wordpress.',
        standard: 'Exibir seu formulário como parte integrante da sua página da web.',
        fullPage: 'Exibir um formulário em tela cheia assim que o site é carregado.',
        popup: 'Seu formulário aparece no centro da tela.<br>Configure opções personalizadas de lançamento, como um atraso de tempo.',
        slider: 'Seu formulário irá deslizar a partir do lado (esquerdo ou direito).<br>Defina as configurações personalizadas de lançamento ou abra com um botão.',
        popover: 'Abrir como um pop-up flutuante quando alguém pressionar o botão, ou configure parâmetros de lançamento personalizados.',
        sideTab: 'Abrir como um painel flutuante quando alguém pressionar o botão, ou configure parâmetros de lançamento personalizados.'
      },
      notification: {
        success: 'URL copiado para a área de transferência com sucesso!',
        error: 'Seu navegador não suporta a funcionalidade de copiar para a área de transferência.'
      }
    },
    integrate: {
      error: {
        loadIntegrations: 'Ops, não conseguimos carregar as integrações neste momento. Por favor, tente novamente em alguns instantes.',
        empty: 'Para gerenciar suas integrações, por favor, abra em uma tela maior.',
        loadWebhook: 'Ops, não conseguimos carregar os webhooks neste momento. Por favor, tente novamente em alguns instantes.'
      },
      infoUrl: 'Informe uma URL que receberá os dados toda vez que alguém enviar um formulário.',
      addWebhook: 'Adicionar webhook...',
      showPayload: 'Mostrar exemplo de payload',
      payload: 'exemplo de payload',
      exampleTitle: 'É um exemplo de como você receberá os dados que enviamos para seus webhooks.'
    },
    results: {
      inbox: {
        hasNoFields: 'Aqui você poderá obter o resultado das suas respostas assim que criar campos e receber respostas.',
        noResponsesFound: 'Nenhuma resposta encontrada.',
        deleteError: 'Não foi possivel deletar a resposta',
        retrievingError: 'Não foi possível obter as respostas nesse momento. Por favor, tente novamente em alguns minutos.',
        showOnlySubmitted: 'Mostrar apenas respostas completas',
        download: 'Download',
        downloadResponse: 'Baixar a resposta selecionada'
      },
      index: {
        switchContent: 'Mostrar/Ocultar detalhes sobre as respostas recebidas para cada questão.',
        switchShow: 'Mostrar detalhes',
        devices: {
          allDevices: 'Todos os dispositivos',
          desktop: 'Desktop',
          mobile: 'Mobile',
          tablet: 'Tablet',
          other: 'Outro'
        },
        times: {
          allTime: 'Tempo todo',
          today: 'Hoje',
          thisWeek: 'Essa semana',
          thisMonth: 'Esse mês',
          thisYear: 'Esse ano'
        },
        statsSchema: {
          views: 'Visualizações',
          starts: 'Inícios',
          responses: 'Respostas',
          completionRate: 'Taxa de conclusão',
          timeToComplete: 'Tempo para concluir'
        }
      },
      insights: {
        questions: 'Questões',
        insights: 'Insights',
        fieldAnswerOk: 'Nosso IA está animado para analisar essas respostas para você. Enquanto isso, você pode se concentrar em coisas que realmente importam - como dominar a arte de cochilar de olhos abertos. 😴😅',
        fieldAnswerLeastFive: 'Você poderá gerar insights via IA assim que este formulário receber pelo menos 5 respostas.',
        error: {
          loadThisTime: 'Oops, não foi possível carregar as percepções no momento. Por favor, tente novamente em alguns instantes.',
          empty: 'Aqui você poderá obter percepções das suas respostas assim que criar campos e receber respostas.',
          loadThisForm: 'Não foi possível carregar as percepções para este formulário. Por favor, tente novamente mais tarde.'
        },
        notification: {
          success: 'Insight criado com sucesso!',
          error: 'Ooops, ocorreu um erro. Por favor, tente novamente em alguns instantes.'
        }
      },
      responses: {
        download: 'Download',
        waiting: 'Lendo respostas...',
        tooltipDownload: 'Fazer download de todas as respostas...'
      }
    }
  },
  workspace: {
    createForm: 'Criar formulário',
    successfully: ' Criado com sucesso!',
    form: 'Formulário '
  },
  me: {
    billing: 'Faturamento',
    plan: 'Plano:',
    payment: 'Pagamento:',
    currency: 'Moeda:',
    cycle: 'Utilizado neste ciclo',
    languageTitle: 'Idioma',
    languageMessage: 'Aqui você pode alterar o idioma da plataforma.',
    languageLabel: 'Selecione o idioma',
    integrations: 'Integrações',
    apiTokenMessage: 'Use o seguinte Token de API para permitir acesso de integração à sua conta',
    copy: 'Copiar',
    loginLink: 'Link de login',
    tooltipCopy: 'Copiar para a área de transferênci',
    revokeApiToken: 'Revogar Token de API',
    someIntegrations: 'Algumas de nossas integrações exigem um Token de API para ter acesso à sua conta.',
    ifYouWant: 'Se você deseja conceder acesso a esse serviço, utilize o botão abaixo para gerar um Token de API.',
    generateApiToken: 'Gerar Token de API',
    supportAccess: 'Acesso ao Suporte',
    youAreCurrently: 'Atualmente, você ',
    allowing: 'está permitindo ',
    ourSupport: 'nossa equipe de suporte ter acesso à sua conta.',
    clickTheButton: 'Clique no botão abaixo para revogar este acesso.',
    not: 'não ',
    enableSupportAccess: 'Habilitar Acesso ao Suporte',
    revokeSupportAccess: 'Revogar Acesso de Suporte',
    errorState: 'Ooops, não foi possível carregar as informações do perfil do usuário no momento. Por favor, tente novamente em alguns instantes.',
    notification: {
      copySuccess: 'Copiado para a área de transferência com sucesso!',
      copyError: 'Seu navegador não suporta a funcionalidade de copiar para a área de transferência.',
      apiTokenGeneratedSuccess: 'Token de API gerado com sucesso!',
      apiTokenGeneratedError: 'Não foi possível gerar seu token de API neste momento. Por favor, tente novamente.',
      apiTokenRevokedSuccess: 'Token de API revogado!',
      apiTokenRevokedError: 'Não foi possível revogar seu token de API neste momento. Por favor, tente novamente.',
      supportAccessGrantedSuccess: 'Acesso ao Suporte concedido com sucesso!',
      supportAccessGrantedError: 'Não foi possível permitir o acesso ao suporte neste momento. Por favor, tente novamente.',
      supportAccessRevokedSuccess: 'Acesso ao Suporte revogado!',
      supportAccessRevokedError: 'Não foi possível negar o acesso ao suporte neste momento. Por favor, tente novamente.',
      channelsUpdated: 'Canais de notificação atualizados com sucesso!',
      channelsUpdateError: 'Erro ao atualizar os canais de notificação. Por favor, tente novamente.'
    },
    notificationTitle: 'Notificações',
    notificationChannels: {
      title: 'Canais de Notificação',
      mail: {
        title: 'Notificações por E-mail',
        subtitle: 'Receba notificações importantes por e-mail.'
      },
      whatsapp: {
        title: 'Notificações por WhatsApp',
        subtitle: 'Receba notificações importantes por WhatsApp.'
      }
    },
    addOns: {
      title: 'Add-ons',
      additionalResponses: {
        title: 'Respostas adicionais',
        subtitle: 'Continuar a receber respostas quando o limite do plano for atingido.',
        description: 'Você pode ativar essa opção para continuar recebendo respostas depois que seu plano atingir o limite de respostas. As respostas excedentes serão cobradas automaticamente de acordo com seu plano.',
        additionalResponsesEnabled: 'Respostas adicionais ativadas!',
        additionalResponsesDisabled: 'Respostas adicionais desativadas!',
        error: 'Não foi possível atualizar esse Add-on no momento. Tente novamente mais tarde.'
      }
    },
    dangerZone: {
      title: 'Zona de perigo',
      subtitle: 'Aqui você pode excluir sua conta e deletar todos os seus dados.',
      cancelAccount: 'Cancelar conta'
    },
    userData: {
      title: 'Dados do Usuário',
      nameLabel: 'Nome',
      namePlaceholder: 'Digite seu nome',
      companyLabel: 'Nome da Empresa',
      companyPlaceholder: 'Digite o nome da sua empresa',
      emailLabel: 'Email',
      emailPlaceholder: 'Digite seu email',
      phoneLabel: 'Telefone',
      phonePlaceholder: 'Digite seu telefone',
      updateSuccess: 'Dados atualizados com sucesso',
      updateError: 'Erro ao atualizar dados'
    },
    companyTitle: 'Empresa',
    companyLabel: 'Nome da Empresa',
    companyPlaceholder: 'Digite o nome da sua empresa',
    companyUpdated: 'Nome da empresa atualizado com sucesso',
    errorUpdatingCompany: 'Erro ao atualizar o nome da empresa',
    save: 'Salvar',
    password: {
      title: 'Redefinir senha',
      description: 'Você receberá um email com instruções para redefinir sua senha.',
      resetButton: 'Enviar email de redefinição',
      resetSuccess: 'Email enviado com sucesso!',
      resetError: 'Erro ao enviar email. Tente novamente.'
    },
    modalConfirmPassword: {
      title: 'Confirmar Senha',
      description: 'Para alterar seu endereço de e-mail, por favor confirme sua senha atual.',
      password: 'Senha',
      cancel: 'Cancelar',
      confirm: 'Confirmar',
      invalidPassword: 'Senha inválida. Por favor, tente novamente.'
    }
  },
  layouts: {
    error: {
      title: 'Ops! 😬',
      message: 'Nós sabemos, nós sabemos, nós cometemos um erro! Você pode entrar em contato conosco em ',
      and: 'E nós podemos ajudar!',
      home: 'Página inicial'
    }
  },
  components: {
    account: {
      ModalAffiliate: {
        affiliate: 'Afiliado',
        sharingLink: 'Ganhe de 20% a 30% de receita recorrente ao compartilhar seu link de indicação com seu público, amigos, clientes, etc.',
        copy: 'Copiar',
        copyToClipboard: 'Copiar para área de transferência',
        error: 'Oops, não foi possível carregar as informações do afiliado neste momento. Por favor, tente novamente em alguns instantes.',
        notification: {
          success: 'URL copiado para a área de transferência!',
          error: 'Seu navegador não suporta a função de copiar para a área de transferência.'
        }
      },
      modalPlans: {
        selectNewPlan: 'Selecione o seu novo plano',
        per: 'por ',
        pay: 'Pagamento:',
        monthly: 'Mensalmente',
        yearly: 'Anualmente',
        select: 'Selecionar',
        yourCurrentPlan: 'Seu plano atual',
        error: 'Oops, não foi possível carregar as informações de afiliado no momento. Por favor, tente novamente em alguns instantes.',
        plans: {
          singleProfessional: '<b>20k</b> respostas/mensais',
          singlePlus: '<b>2k</b> respostas/mensais',
          singleStarter: '<b>200</b> respostas/mensais',
          singleFree: '<b>20</b> respostas/mensais',
          unlimited: 'Formulários ilimitados',
          customEndingScreens: 'Telas de encerramento personalizadas',
          automaticKeyword: 'Extração automática de palavras-chave',
          sentimentDetection: 'Detecção de sentimento',
          emotionDetection: 'Detecção de emoção'
        }
      }
    },
    auth: {
      theSocialButtons: {
        or: 'OU'
      }
    },
    common: {
      oneTimePassword: {
        wait: 'Aguarde até que o usuário insira o número de caracteres necessário',
        invalid: 'Dados colados inválidos'
      },
      alertDialog: {
        ok: 'Ok, entendi!'
      },
      closeButton: {
        close: 'Fechar'
      },
      confirmationDialog: {
        wait: 'Espere um minuto!',
        danger: 'perigo',
        no: 'Não',
        yes: 'Sim'
      },
      errorNotification: {
        default: 'Não é possível realizar a operação.',
        close: 'Fechar'
      },
      inlineEditor: {
        tooltip: 'Clique para editar'
      },
      stateBlock: {
        default: 'Ops, ocorreu um erro. Por favor, tente novamente em alguns instantes.'
      },
      successNotification: {
        default: 'Operação realizada com sucesso!'
      },
      UndoNotification: {
        default: 'Ação executada com sucesso!',
        close: 'Cerca'
      },
      UpgradeBadge: {
        available: 'Essa funcionalidade está disponível nos seguintes planos:',
        pleaseUpgrade: '.<br> Por favor, faça um upgrade do seu plano para utilizá-la.',
        upgradeYourPlan: 'Atualize seu plano para usar esse recurso.'
      },
      userAvatar: {
        owner: 'dono'
      },
      userMiniProfile: {
        remove: 'Remover',
        fromWorkspace: 'Do espaço de trabalho...',
        revokeAccess: 'Remover o acesso a este espaço de trabalho'
      }
    },
    formEdit: {
      fieldTypes: {
        calendly: {
          invalid: 'O calendário aparecerá assim que uma URL válida do Calendly for fornecida.',
          enablePrefill: 'Ativar pré-preenchimento',
          name: 'Nome',
          email: 'Email',
          customField: 'Campo Customizado',
          deleteCustomField: 'Excluir campo',
          deleteFieldTooltip: 'Excluir pré-preenchimento do campo customizado',
          addFieldTooltip: 'Adicionar pré-preenchimento do campo customizado',
          nameField: 'Campo de nome...',
          emailField: 'Campo de e-mail...',
          customFieldLabel: 'Campo customizado...'
        },
        date: {
          PreviewOnly: 'Apenas visualização  👀',
          day: 'Dia',
          month: 'Mês',
          year: 'Ano',
          dateFormat: 'Formato de data'
        },
        dropdown: {
          selectAnOption: 'Selecione uma opção...',
          choices: 'Opções',
          addChoice: 'Adicionar escolha',
          editChoices: 'Editar escolhas',
          oneItem: 'Um item por linha',
          onlyUniqueItems: 'Por favor, remova itens duplicados.',
          minCount: 'Pelo menos 2 opções devem ser inseridas.',
          cancel: 'Cancelar',
          notificationSuccess: 'Opções salvas!',
          title: 'Mãos para cima!',
          messageFollowingChoices: 'As seguintes opções são usadas em regras lógicas.',
          messageWeAdvice: 'Se você prosseguir, aconselhamos que você revise suas regras lógicas, pois elas serão violadas.<br>Tem certeza de que deseja salvar as novas opções?',
          noButtonText: 'Cancelar',
          yesButtonText: 'Salvar opções',
          randomized: 'Randomizado',
          alphabetically: 'Ordem alfabética'
        },
        email: {
          PreviewOnly: 'Apenas visualização  👀'
        },
        endingScreen: {
          thisEnding: 'Esta tela de finalização não será exibida. Em vez disso, o usuário será redirecionado para:',
          sayBye: 'Diga adeus aqui.',
          callToAction: 'Exibir botão',
          buttonText: 'Texto do botão',
          buttonLink: 'Link do botão',
          tooltip: 'Clique para editar ✍️. <br> Digite <b>@</b> para inserir uma variável.',
          reload: 'Recarregar',
          help: 'Redireciona automaticamente o usuário para outra página ao finalizar.',
          redirectTo: 'Redirecionar para'
        },
        longText: {
          previewOnly: 'Apenas visualização  👀',
          typeYourAnswer: 'Digite sua resposta aqui...',
          toMakeLineBreak: 'Para fazer uma quebra de linha',
          keywordExtraction: 'Extração de palavras-chave',
          textAnalysis: 'Análise de texto',
          textClassification: 'Classificação de texto',
          enableKeywordExtraction: 'Permite a extração automática <br> de palavras-chave das respostas.',
          enableTextAnalysis: 'Detecta automaticamente a emoção <br>(amor, alegria, surpresa, tristeza, raiva e medo) e o sentimento (👍 👎) das respostas.',
          enableTextClassification: 'Classifica automaticamente as respostas em categorias predefinidas.<br>Este recurso é gratuito enquanto estiver em fase BETA.',
          maxCharacters: 'Máximo de caracteres',
          categories: {
            label: 'Categorias',
            placeholder: 'Adicione uma categoria por linha',
            help: 'Adicione até 10 categorias para classificar automaticamente as respostas.'
          },
          autoUpdateCategories: 'Adicionar categorias automaticamente',
          enableAutoUpdateCategoriesHelp: 'Ative para adicionar automaticamente as categorias com base nas respostas dos usuários.'
        },
        multipleChoice: {
          chooseMany: 'Escolha quantos você quiser.',
          option: 'Opção...',
          removeChoice: 'Remover escolha',
          other: 'Outra',
          addChoice: 'Adicionar escolha',
          error: 'Parece que a opção que você está tentando adicionar já existe. Por favor, renomeie a opção atual para adicionar outra.',
          confirmDeletion: 'Essa opção é usada em regras lógicas.<br>Se você prosseguir, você quebrará a lógica.<br><br>Tem certeza de que deseja excluí-la?',
          multipleSelection: 'Seleção múltipla',
          randomized: 'Randomizado',
          unlimited: 'Ilimitado',
          exactNumber: 'Número exato',
          range: 'Faixa'
        },
        pictureChoice: {
          multipleSelection: 'Seleção múltipla',
          randomized: 'Randomizado',
          superSize: 'Super tamanho'
        },
        number: {
          previewOnly: 'Apenas visualização 👀',
          typeYourAnswer: 'Digite sua resposta aqui...',
          decimal: 'Decimal',
          decimalPlaces: 'Casas decimais',
          decimalSeparator: 'Separador decimal',
          prefix: 'Prefixo',
          suffix: 'Sufixo'
        },
        opinionScale: {
          previewOnly: 'Apenas visualização 👀',
          fromLabel: 'Rótulo De',
          toLabel: 'Rótulo até',
          dontLike: 'Por exemplo, não gosto disso',
          loveIt: 'Por exemplo, adoro isso!',
          from: 'De',
          to: 'até'
        },
        phone: {
          previewOnly: 'Apenas visualização 👀',
          defaultCountryCode: 'Código de país padrão'
        },
        rating: {
          previewOnly: 'Apenas visualização 👀',
          items: 'Itens',
          icon: 'Icone',
          star: 'Estrela',
          heart: 'Coração',
          user: 'Usuario',
          up: 'Polegar para cima',
          tick: 'Marcação',
          crown: 'Coroa',
          trophy: 'Troféu'
        },
        shortText: {
          previewOnly: 'Apenas visualização 👀',
          typeYourAnswer: 'Digite sua resposta aqui...',
          enablesAutomaticKeyword: 'Permite a extração automática <br> de palavras-chave das respostas.',
          automaticallyDetectEmotion: 'Detecta automaticamente a emoção  <br> (amor, alegria, surpresa, tristeza, raiva e medo)<br> e o sentimento (👍 👎) nas respostas.',
          keywordExtraction: 'Extração de palavras-chave',
          textAnalysis: 'Análise de texto',
          textClassification: 'Classificação de texto',
          enableKeywordExtraction: 'Permite a extração automática de palavras-chave<br>  a partir de respostas.',
          enableTextAnalysis: 'Detecta automaticamente a emoção <br>(amor, alegria, surpresa, tristeza, raiva e medo) e o sentimento (👍 👎) das respostas.',
          enableTextClassification: 'Classifica automaticamente as respostas em categorias predefinidas.<br>Este recurso é gratuito enquanto estiver em fase BETA.',
          maxCharacters: 'Máximo de caracteres',
          fieldMask: 'Máscara do campo',
          maskTooltip: '<b>#</b> : Qualquer número<br><b>@</b> : Qualquer letra<br><b>*</b> : Letra ou número',
          maskHelp: '<b>#</b> representa qualquer número, <b>@</b> qualquer letra e <b>*</b> pode ser uma letra ou número. Para mais detalhes, acesse <a href="https://suporte.yayforms.com/article/226-como-adicionar-uma-mascara-no-seu-campo-de-texto-curto" target="_blank"><b>este guia completo</b></a>',
          maskPlaceholder: '###.###.###-##',
          categories: {
            label: 'Categorias',
            placeholder: 'Adicione uma categoria por linha',
            help: 'Adicione até 10 categorias para classificar automaticamente as respostas'
          },
          autoUpdateCategories: 'Adicionar categorias automaticamente',
          enableAutoUpdateCategoriesHelp: 'Ative para adiciona automaticamente as categorias com base nas respostas dos usuários.'
        },
        statement: {
          sayHiHere: 'Diga oi aqui!',
          continue: 'Continuar',
          hideButton: 'Ocultar botão',
          button: 'Botão'
        },
        website: {
          previewOnly: 'Apenas visualização 👀'
        },
        welcomeScreen: {
          start: 'Começar',
          button: 'Botão'
        },
        yesNo: {
          y: 'S',
          n: 'N',
          yes: 'Sim',
          no: 'Não'
        }
      },
      fieldHeader: {
        clickToEdit: 'Clique para editar ✍️. <br>',
        toInsert: 'para inserir uma variável.',
        atSign: 'Digite <b>@</b> ',
        placeholder: 'Descrição (opcional)',
        titlePlaceholder: 'Sua questão aqui.',
        align: 'esquerda'
      },
      fieldListItem: {
        duplicate: 'Duplicar...',
        delete: 'Excluir...',
        dupDel: 'Duplicar, Excluir...'
      },
      fieldRequired: {
        required: 'Preenchimento obrigatório'
      },
      fieldSettingSwitch: {
        upgradeYourPlan: '<b>Faça o upgrade do seu plano para utilizar esse recurso.</b>'
      },
      formVariable: {
        clickToEdit: 'Clique para editar'
      },
      LogicActionEditor: {
        clickToChoose: 'Clique para escolher outro campo.'
      },
      logicConditionEditor: {
        clickToChoose: 'Clique para escolher um campo.',
        variables: 'Variáveis',
        fields: 'Campos',
        addNewCondition: 'Adicionar nova condição',
        condition: '+ condição',
        delete: 'Excluir',
        deleteThisCondition: 'Excluir esta condição',
        if: 'SE'
      },
      logicLogicalOperator: {
        or: 'ou',
        and: 'e'
      },
      logicRuleEditor: {
        then: 'Então',
        deleteThisRule: 'Excluir esta regra',
        delete: 'Excluir',
        deleteAction: 'Excluir ação',
        deleteThisAction: 'Excluir esta ação',
        addAction: 'Adicionar ação'
      },
      logicRuleItem: {
        if: 'SE',
        clickToEdit: 'Clique para editar esta regra'
      },
      mediaRenderer: {
        remove: 'Remover'
      },
      modalAddMedia: {
        addImage: 'Adicionar imagem ou vídeo',
        selectImageToUpload: 'Selecione uma imagem de até 2MB.',
        allowedExtensions: 'Extensões permitidas: svg, jpeg, png, jpg, gif.',
        optimizingTheFile: 'Otimizando o arquivo para a web.',
        pleaseWait: 'Por favor, aguarde…',
        egImage: 'P. ex. https://suaempresa.com/logo.png',
        enterUrlImage: 'Informe a URL da imagem que você deseja incluir no seu formulário.',
        egYoutube: 'P. ex. https://www.youtube.com/watch?v=L_LUpnjgPso',
        enterValidImageUrl: 'Informe uma URL válida da imagem.',
        enterUrlVideo: 'Informe a URL do vídeo do Youtube, Vimeo ou Panda Video.',
        enterValidYoutube: 'Informe uma URL válida do Youtube,Vimeo ou Panda Video.',
        cancel: 'Cancelar',
        add: 'Adicionar',
        imageUpload: 'Upload de imagem',
        imageUrl: 'URL da imagem',
        gallery: 'Galeria',
        areYouSure: 'Você tem certeza que deseja excluir essa foto da sua galeria?',
        errorMedia: 'A imagem deve ser do tipo: png, jpg, jpeg, gif, com o tamanho máximo de 2MB.',
        errorUpload: 'Desculpe, não foi possivel efetuar o upload',
        errorDelete: 'Desculpe,não foi possivel deletar a midia',
        errorFind: 'Desculpe, não foi possivel fazer a busca das midias',
        emptyGallery: 'Suas imagens aparecerão aqui assim que você fizer o upload delas.'
      },
      modalEditVariables: {
        variables: 'Variáveis',
        variablesAllow: 'As variáveis permitem que você faça cálculos personalizados sobre regras lógicas com base nas respostas dos seus formulários.',
        defaultVariables: 'Variáveis padrão',
        customVariables: 'Variáveis ​​personalizadas',
        matches: 'Apenas letras, números e sublinhados são permitidos.',
        unique: 'Este nome de variável já está em uso.',
        removeVariable: 'Remover variável',
        addVariable: 'Adicionar variável',
        close: 'Fechar',
        confirmDeletionLogic: 'Esta variável é utilizada em regras lógicas.<br><br>Tem certeza de que deseja excluir esta variável e as regras lógicas que a utilizam?',
        confirmDeletionContent: 'Esta variável está sendo usada no conteúdo de uma questão.<br><br>Você deseja excluir esta variável e remover todas as suas ocorrências das questões que a estão utilizando?',
        message: 'Existem erros no formulário.<br>Deseja fechá-lo mesmo assim?',
        variant: 'primário',
        noButtonText: 'Não, deixe-me corrigir.',
        yesButtonText: 'Sim, feche sem salvar.'
      },
      richInputSuggestion: {
        variables: 'Variáveis',
        notFoundVariables: 'Nenhuma variável encontrada.'
      },
      theFieldLayout: {
        imageVideo: 'Imagem / Vídeo',
        add: '+ Adicionar',
        remove: 'Remover',
        onTheTop: 'No topo',
        onTheMiddle: 'No meio',
        height: 'Altura',
        width: 'Largura',
        auto: 'auto',
        altText: 'Texto alternativo',
        imageDescription: 'Descrição da imagem'
      },
      theFieldList: {
        welcomeScreen: 'Tela de boas vindas',
        addWelcomeScreen: 'Adicionar tela de boas-vindas',
        questions: 'Questões',
        AddQuestion: 'Adicionar questão',
        endingScreens: 'Telas finais',
        AddEndingScreens: 'Adicionar tela final',
        confirmDeletionLogic: 'Esta questão possui regras lógicas.<br>Se você prosseguir, irá quebrar a lógica.<br><br>Tem certeza de que deseja excluí-la?',
        confirmDeletionOtherLogic: 'Esta questão é utilizada nas regras lógicas de outras questões. Se você prosseguir, irá quebrar a lógica.<br><br>Tem certeza de que deseja excluí-la?',
        confirmResponsesLossEnding: 'Se você prosseguir, perderá o número de visualizações e a taxa de desistência associadas a ela. Tem certeza de que deseja excluí-la?',
        confirmResponsesLossWelcome: 'Esta questão já possui respostas. Se você prosseguir, perderá todas as respostas. Recomendamos que você faça o download das respostas antes de prosseguir. Tem certeza de que deseja excluir este campo e todas as suas respostas?'
      },
      theFieldPreview: {
        chooseOrAdd: 'Escolha uma questão ou adicione uma nova no painel esquerdo.'
      },
      theFieldSettings: {
        welcome: 'A tela de boas-vindas e a tela de encerramento não podem ser alteradas.',
        type: 'Tipo',
        settings: 'Configurações',
        select: 'Selecione uma questão para mostrar suas configurações.',
        alert: 'Esta questão já possui respostas, portanto, você não pode alterar o seu tipo neste momento. Em vez disso, recomendamos que você faça o download das respostas, exclua o campo e adicione um novo do tipo que você precisa.'
      },
      theFormLeftMenu: {
        content: 'Conteúdo',
        design: 'Design',
        settings: 'Config'
      },
      theFormRightMenu: {
        question: 'Questão',
        logic: 'Lógica'
      },
      theFormSettings: {
        formSettings: 'Configurações do formulário',
        language: 'Melhor idioma',
        theLanguageToShow: 'O idioma para exibir os botões, dicas e mensagens de erro do formulário',
        integrationsSettings: 'Configurações de integrações',
        hidePowered: 'Ocultar Feito com Yay! Forms',
        removeTheCredits: 'Remover os créditos exibidos no rodapé dos seus formulários',
        closeForm: 'Fechar o formulário para novas respostas',
        sendIncomplete: 'Enviar respostas incompletas',
        enableSending: 'Ative o envio de respostas incompletas para a plataforma integrada.<br>Se esta configuração estiver ativada, você deve especificar um atraso (em minutos).<br>Esse atraso indica o tempo de espera antes que a resposta incompleta seja enviada.',
        waitingTime: 'Tempo de espera em minutos',
        metaTagsSettings: 'Configurações de Meta Tags',
        title: 'Titulo',
        enterTitle: 'Insira o titulo',
        description: 'Descrição',
        enterDescription: 'Insira a descrição',
        previewImage: 'Imagem de preview',
        aiFeedback: 'Ativar feedback de IA',
        aiFeedbackHelp: 'Ative o feedback de IA para gerar feedbacks personalizadas com base nas respostas do usuário.',
        prompt: {
          label: 'Prompt de IA',
          placeholder: 'Ex. Se a pontuação do usuário for inferior a 5, diga ao usuário como melhorá-la com base em suas respostas.',
          help: 'Configure um prompt para gerar feedback personalizado das respostas com inteligência artificial. <a href="https://suporte.yayforms.com/article/181-guia-de-placeholders-para-personalizacao-de-emails-e-mensagens" target="_blank"><b><u>Saiba mais</u></b></a>.'
        }
      },
      theFormVariables: {
        variables: 'Variáveis',
        removeVariable: 'Remover variável',
        addVariable: 'Adicionar variável',
        selectAQuestion: 'Selecione uma questão para mostrar suas variáveis.'
      },
      theLogicRuleList: {
        variables: 'Variáveis',
        createVariables: 'Criar variáveis para utilizar em suas regras lógicas.',
        pleaseSelect: 'Por favor, selecione uma questão no painel esquerdo para adicionar uma nova regra lógica, uma vez que não é possível criá-la para telas de encerramento.',
        rules: 'Regras',
        theFollowingRules: 'As seguintes regras serão aplicadas quando alguém responder à questão.',
        else: 'SENÃO',
        addRule: 'Adicionar regra',
        byDefault: 'Por padrão',
        otherwise: 'Caso contrário',
        goTo: 'Vá para',
        selectAQuestion: 'Selecione uma questão para mostrar suas regras lógicas.'
      },
      themeListItem: {
        clickToSelect: 'Clique para selecionar este tema.',
        question: 'Questão',
        answer: 'Resposta',
        thisTheme: 'Este tema já está em uso para este formulário.',
        selectThisTheme: 'Selecione este tema.',
        editFont: 'Edite a fonte e as cores deste tema.',
        duplicate: 'Duplicar este tema',
        thisThemeIsBeing: 'Este tema está sendo usado por este formulário. Por favor, selecione outro tema se você deseja excluir este.',
        deleteThisTheme: 'Excluir este tema',
        cannotEditDefaultTheme: 'Não é possível editar o tema padrão.',
        cannotDeleteDefaultTheme: 'Não é possível excluir o tema padrão.'
      },
      theThemeEditor: {
        goBack: 'Volte',
        themeName: 'Nome do tema',
        font: 'Nome da fonte',
        fontSize: 'Tamanho da fonte',
        buttonBorderRadius: 'Cantos',
        page: 'Página',
        question: 'Questão',
        button: 'Botão',
        revert: 'Reverter',
        save: 'Salvar',
        sm: 'P',
        md: 'M',
        lg: 'G',
        small: 'Pequeno',
        medium: 'Médio',
        large: 'Grande',
        title: 'Descartar mudanças?',
        message: 'Existem alterações não salvas neste tema. Tem certeza de que deseja descartá-las?',
        noButtonText: 'Cancelar',
        yesButtonText: 'Sim, descartar alterações',
        discardChangesTooltip: 'Descartar alterações',
        saveChangesTooltip: 'Salvar alterações no tema'
      },
      theThemeList: {
        themes: 'Temas',
        newTheme: 'Novo tema',
        confirmDeletion: 'Tem certeza de que deseja excluir o tema?',
        myThemes: 'Meus temas',
        gallery: 'Galeria'
      }
    },
    formIntegrate: {
      logs: {
        title: 'Registros de Integração',
        columnNameIntegration: 'Nome da Integração',
        columnType: 'Tipo de Integração',
        columnTrigger: 'Gatilho',
        columnStatus: 'Status',
        columnCreatedAt: 'Criado em',
        columnActions: 'Ações',
        retry: 'Reenviar',
        retrySuccess: 'Integração reenviada com sucesso!',
        retryError: 'Falha ao reenviar a integração.',
        errorFetchingLogs: 'Erro ao buscar os registros de integração.',
        refresh: 'Atualizar',
        loading: 'Carregando registros...',
        emptyState: 'No logs available to display.',
        modalTitle: 'Detalhes do Log',
        details: 'Detalhes',
        msgBox: {
          title: 'Espere um instante!',
          confirmRetry: 'Tem certeza de que deseja reenviar esta integração?',
          yesButtonText: 'Sim',
          noButtonText: 'Não'
        },
        loadingMessage: 'Carregando detalhes do log...',
        noRowsTitle: 'Nenhum registro de integração encontrado.',
        noRowsDescription: 'Os registros de integração serão exibidos aqui assim que você começar a enviar dados para suas integrações.',
        logNotFound: 'Log não encontrado.',
        status: {
          succeeded: 'Sucesso',
          failed: 'Falha',
          voided: 'Ignorado'
        }
      },
      credentialsDropdown: {
        connect: 'Conectar ',
        account: ' conta',
        loading: 'Carregando ',
        accounts: ' contas',
        connectOther: 'Conectar outro ',
        authorize: 'Autorize Yay! Forms'
      },
      integrationCard: {
        active: 'Ativo',
        inactive: 'Inativo',
        tooltip: 'Ativar ou desativar a integração',
        by: 'Por',
        or: 'ou',
        enabledTip: 'Esta integração está ativada.',
        disabledTip: 'Esta integração foi desativada devido a um erro ou por decisão do proprietário do formulário.<br>Por favor, edite as configurações da integração e salve novamente para habilitar a integração.',
        configure: 'Configurar',
        settings: 'Configurações',
        test: 'Testar',
        gettingStarted: 'Começando',
        copyToClipboard: 'Copiar para área de transferência',
        copy: 'Copiar',
        itSeemsLike: 'Parece que você ainda não possui um Token de API.',
        generate: 'Gerar token de API',
        see: 'Veja o guia do ',
        guide: '',
        open: 'Abrir',
        yourCurrentPlan: 'Seu plano atual ',
        doesntAllow: ' Não permite que você integre com ',
        pleaseUpgrade: ' Por favor, atualize seu plano para prosseguir.',
        integration: ' Integração',
        notification: {
          apiTokenCopiedSuccess: 'Token da API copiado para a área de transferência!',
          apiTokenCopiedError: 'Seu navegador não suporta a função de copiar para a área de transferência.',
          apiTokenGeneratedSuccess: 'Token da API gerado com sucesso!',
          apiTokenGeneratedError: 'Não foi possível gerar seu token da API neste momento. Por favor, tente novamente.',
          testSuccess: 'Teste enviado com sucesso!',
          testError: 'Não foi possível enviar o teste no momento. Por favor, tente novamente mais tarde.',
          retrySuccess: 'Integração reenviada com sucesso!',
          retryError: 'Falha ao reenviar a integração.'
        }
      },
      modalAddWebhook: {
        title: 'Novo webhook',
        name: 'Nome',
        egSendResponse: 'Por exemplo, enviar uma resposta para o Slack.',
        aNameToHelpYouRemember: 'Um nome para ajudar você a lembrar o que este webhook faz.',
        endpoint: 'Endpoint',
        sendDataTo: 'Enviaremos os dados para esta URL.',
        yourWebsite: 'https://seuwebsite.com/webhook',
        labelSecret: 'Segredo',
        placeholderSecret: 'Meu segredo...',
        helpSecret: 'Se especificado, será usado para assinar o payload do webhook com HMAC SHA256, permitindo que você verifique que ele veio do Yay! Forms.',
        labelSsl: 'Verificação SSL',
        helpSsl: 'Marque esta opção se você deseja que o Yay! Forms verifique os certificados SSL ao entregar os payloads.',
        labelPastIntegrations: 'Executar em Respostas Anteriores',
        helpPastIntegrations: 'Marque se você deseja executar esta integração em todas as respostas anteriores.',
        LabelHeader: 'Cabeçalho personalizado',
        placeholderHeaderName: 'Nome do cabeçalho...',
        placeholderHeaderValue: 'Valor do cabeçalho...',
        helpHeaderName: 'Se especificado, será adicionado um cabeçalho personalizado ao payload do webhook.',
        labelTriggerCondition: 'Condição de acionamento (opcional)',
        placeholderTriggerCondition: 'Ex. {{id_do_campo}} CONTAINS "sim" AND {{id_do_campo}} >= "25000"',
        helpTriggerCondition: 'A fórmula que especifica quando enviar a webhook. Deixe em branco para sempre enviar a webhook quando o formulário for submetido. <a href="https://suporte.yayforms.com/article/204-como-criar-expressoes-para-execucao-condicional-de-integracoes" target="_blank"><b><u>Saiba mais</u></b</a>.',
        labelCaseInsensitive: 'Condição de acionamento sem distinção entre maiúsculas e minúsculas',
        helpCaseInsensitive: 'Marque esta caixa se desejar tornar a condição de envio insensível a maiúsculas e minúsculas. Se marcada, expressões como "Eu Aceito" ou "eu aceito" serão consideradas iguais.',
        cancel: 'Cancelar',
        save: 'Salvar',
        webhook: 'Webhook salvo com sucesso!'
      },
      modalConfigureOauthIntegration: {
        disconnect: 'Desconectar',
        cancel: 'Cancelar',
        saveIntegration: 'Salvar integração',
        saving: 'Salvando...',
        integration: 'Integração de {integrationName}',
        confirmDeletion: 'Tem certeza de que deseja excluir o',
        notification: {
          integrationSavedSuccess: ' Integração salva com sucesso!',
          disconnectSuccess: ' desconectado com sucesso!'
        }
      },
      ModalConfigureWebhookIntegration: {
        disconnect: 'Desconectar',
        cancel: 'Cancelar',
        saveIntegration: 'Salvar',
        confirmDeletion: 'Tem certeza de que deseja excluir o',
        integration: ' Integração',
        notification: {
          integrationSavedSuccess: ' Integração salva com sucesso!',
          disconnectSuccess: ' desconectado com sucesso!'
        }
      },
      modalEditWebhook: {
        title: 'Editar webhook',
        LabelName: 'Nome',
        placeholderName: 'Por exemplo, enviar uma resposta para o Slack.',
        helpName: 'Um nome para ajudar você a lembrar o que este webhook faz.',
        labelUrl: 'Endpoint',
        placeholderUrl: 'Novo endpoint...',
        helpUrl: 'Enviaremos os dados para esta URL.',
        labelSecret: 'Segredo',
        placeholderSecret: 'Meu segredo...',
        helpSecret: 'Se especificado, será usado para assinar o payload do webhook com HMAC SHA256, permitindo que você verifique que ele veio do Yay! Forms.',
        labelSsl: 'Verificação SSL',
        helpSsl: 'Marque esta opção se você deseja que o Yay! Forms verifique os certificados SSL ao entregar os payloads.',
        labelPastIntegrations: 'Executar em Respostas Anteriores',
        helpPastIntegrations: 'Marque se você deseja executar esta integração em todas as respostas anteriores.',
        LabelHeader: 'Cabeçalho personalizado',
        placeholderHeaderName: 'Nome do cabeçalho...',
        placeholderHeaderValue: 'Valor do cabeçalho...',
        helpHeaderName: 'Se especificado, será adicionado um cabeçalho personalizado ao payload do webhook.',
        labelTriggerCondition: 'Condição de acionamento (opcional)',
        placeholderTriggerCondition: 'Ex. {{id_do_campo}} CONTAINS "sim" AND {{id_do_campo}} >= "25000"',
        helpTriggerCondition: 'A fórmula que especifica quando enviar a webhook. Deixe em branco para sempre enviar a webhook quando o formulário for submetido. <a href="https://suporte.yayforms.com/article/204-como-criar-expressoes-para-execucao-condicional-de-integracoes" target="_blank"><b><u>Saiba mais</u></b</a>.',
        labelCaseInsensitive: 'Condição de acionamento sem distinção entre maiúsculas e minúsculas',
        helpCaseInsensitive: 'Marque esta caixa se desejar tornar a condição de envio insensível a maiúsculas e minúsculas. Se marcada, expressões como "Eu Aceito" ou "eu aceito" serão consideradas iguais.',
        cancel: 'Cancelar',
        save: 'Salvar'
      },
      modalSmtp: {
        title: 'Configurações SMTP',
        host: 'Host',
        hostLabel: 'Host',
        hostPlaceholder: 'Insira seu host SMTP',
        hostHelp: 'O endereço do seu servidor SMTP.',
        port: 'Porta',
        portLabel: 'Porta',
        portPlaceholder: 'Insira sua porta SMTP',
        portHelp: 'A porta usada pelo seu servidor SMTP (geralmente 587 para TLS).',
        encryption: 'Criptografia',
        encryptionLabel: 'Criptografia',
        encryptionNone: 'Nenhuma',
        encryptionSSL: 'SSL',
        encryptionTLS: 'TLS',
        encryptionHelp: 'O método de criptografia para proteger sua conexão SMTP.',
        username: 'Usuário',
        usernameLabel: 'Usuário',
        usernamePlaceholder: 'Insira seu usuário SMTP',
        usernameHelp: 'O nome de usuário da sua conta SMTP.',
        password: 'Senha',
        passwordLabel: 'Senha',
        passwordPlaceholder: 'Insira sua senha SMTP',
        passwordHelp: 'A senha da sua conta SMTP.',
        from: 'De',
        fromLabel: 'Endereço de email',
        fromPlaceholder: 'Insira o seu endereço de email remetente',
        fromHelp: 'O endereço de email do qual os emails serão enviados.',
        name: 'Nome',
        nameLabel: 'Nome',
        namePlaceholder: 'Insira seu nome',
        nameHelp: 'O nome que aparecerá como remetente.',
        cancel: 'Cancelar',
        save: 'Testar e salvar',
        success: 'Configurações SMTP salvas com sucesso!',
        testing: 'Testando...'
      },
      theIntegrationNavbar: {
        integrations: 'Integrações'
      },
      webhookListItem: {
        active: 'Ativo',
        inactive: 'Inativo',
        tooltip: 'Editar, excluir, testar...',
        edit: 'Editar',
        delete: 'Excluir',
        test: 'Testar',
        confirmDelete: 'Você tem certeza de que deseja excluir este webhook?',
        enabledTip: 'Esta webhook está ativada.',
        disabledTip: 'Esse webhook foi desativada devido a um erro ou por decisão do proprietário do formulário.<br>Edite as configurações da webhook e salve novamente para habilitar a integração.',
        switchTooltip: 'Ativar ou desativar essa webhook'
      }
    },
    formResults: {
      emotions: {
        love: 'amor',
        joy: 'alegria',
        surprise: 'surpresa',
        sadness: 'tristeza',
        anger: 'raiva',
        fear: 'medo'
      },
      sentiments: {
        positive: 'positivo',
        neutral: 'neutro',
        negative: 'negativo'
      },
      answerBars: {
        showing: 'Exibindo ',
        answersFromSubmitted: ' Respostas de formulários enviados de',
        onlyAnswers: 'Apenas as respostas dos formulários enviados são mostradas aqui.',
        answers: 'respostas'
      },
      answerList: {
        emotions: 'Emoções',
        sentiments: 'Sentimentos',
        keywords: 'Palavras-chave',
        textClassification: 'Classificação de texto',
        messageKeywordsWill: 'As palavras-chave serão exibidas aqui assim que este formulário receber um número mínimo de respostas.',
        messageTextClassification: 'A classificação de texto será exibida aqui assim que este formulário receber um número mínimo de respostas.',
        copyKeywords: 'Copiar palavras-chave para a área de transferência',
        copyTextClassification: 'Copiar classificação de texto para a área de transferência',
        copy: 'Copiar',
        occurrencesFor: ' ocorrências de ',
        answers: 'Respostas',
        messageNoAnswers: 'Nenhuma resposta foi encontrada com o filtro atual.',
        loading: 'Carregando...',
        notificationSuccess: 'Palavras-chave copiadas para a área de transferência!',
        notificationError: 'Seu navegador não suporta a função de copiar para a área de transferência.'
      },
      answerVerticalBars: {
        showing: 'Exibindo ',
        answersFromSubmitted: ' Respostas de formulários enviados de',
        onlyAnswers: 'Apenas as respostas dos formulários enviados são mostradas aqui.',
        answers: 'respostas',
        npsTooltip: 'NPS = % Promotores - % Detratores'
      },
      emojiBadge: {
        value: 'valor',
        answers: 'respostas'
      },
      fieldSummary: {
        uniqueViews: 'Visualizações únicas',
        views: 'Visualizações',
        itIncludes: 'Isso inclui respostas de formulários enviados e não enviados.',
        answers: 'Respostas',
        peopleWho: 'Pessoas que desistiram de responder o formulário nesta questão.',
        DropOff: 'Desistência',
        resultsWillAppears: 'Os resultados aparecerão aqui assim que alguém responder este formulário.',
        weCouldntLoad: 'Ops, não foi possível carregar as respostas neste momento. Por favor, tente novamente em alguns instantes.'
      },
      fileUploadCellRenderer: {
        view: 'Visualizar',
        alert: 'Falha ao baixar o arquivo!'
      },
      InboxItem: {
        notAnswered: 'Não respondido',
        deleteResponse: 'Deletar Resposta',
        createdAt: 'Criado em:',
        submittedAt: 'Enviado em:'
      },
      MarkdownCellRenderer: {
        showLess: 'Mostrar menos',
        showMore: 'Mostrar mais'
      },
      modalGenerateInsight: {
        generateInsight: 'Gerar insight',
        currently: 'Atualmente, temos um limite específico para a quantidade de dados que podemos analisar de uma só vez. Portanto, selecione o intervalo do qual você deseja obter percepções, e faremos o possível para analisar o máximo de respostas possível dentro desse intervalo.',
        labelInterval: 'Qual intervalo você gostaria de analisar?',
        labelWhatKind: 'Que tipo de percepção você gostaria de obter?',
        TooltipSelect: 'Selecione uma receita para gerar percepções sobre ela.',
        select: 'Selecione...',
        resultsPreview: 'Prévia dos resultados.',
        inThisRange: 'Neste intervalo, iremos analisar <strong>{analyze}</strong> respostas de <strong>{generate}</strong> para gerar insights',
        usingRecipe: 'usando a receita',
        cancel: 'Cancelar',
        theInsight: 'A criação dos insights foi iniciada...'
      },
      textAnswerRender: {
        tooltipEmotion: 'Ainda estamos analisando a emoção deste texto. Estará disponível em breve.',
        tooltipSentiment: 'Ainda estamos analisando o sentimento deste texto. Estará disponível em breve.'
      },
      theFieldInsightsPanel: {
        generateNewInsight: 'Gerar novo insights...',
        generateInsight: 'Gerar insights',
        generatingInsight: 'Gerando insights...',
        generating: 'Gerando...',
        endAt: 'Termina em:',
        insight: 'Insight',
        copyClipboard: 'Copiar para a área de transferência',
        messageOurIa: 'Nossa IA está animada para analisar essas respostas para você. Enquanto isso, você pode se concentrar em coisas que realmente importam, como dominar a arte de cochilar de olhos abertos. 😴😅',
        messageCouldntLoad: 'Ops, não foi possível carregar os insights neste momento. Por favor, tente novamente em alguns instantes.',
        pleaseWait: 'Por favor, aguarde até que o insight seja gerado.',
        insightNotIf: 'Insight copiado para a área de transferência!',
        yourBrowserDoesNotSupport: 'Seu navegador não suporta copiar para a área de transferência.'
      },
      theInboxTable: {
        loading: 'Carregando...',
        clickToChoose: 'Clique para escolher um campo.',
        fields: 'Campos',
        errorGetAnswers: 'Não foi possivel buscar as respostas'
      },
      theInsightFieldList: {
        questions: 'Questões'
      },
      theResponsesTable: {
        placeholder: 'Pesquisar em questões abertas...',
        deleteSelected: 'Excluir respostas selecionadas',
        loadingMessage: 'Carregando respostas, por favor, aguarde...',
        title: 'Ainda não há resultados para mostrar.',
        description: 'Os resultados serão exibidos aqui assim que alguém responder ao formulário.',
        alert: 'Pelo menos uma resposta deve ser selecionada para excluir.',
        confirmDeletion: 'Tem certeza de que deseja excluir o ',
        selectedResponse: ' resposta(s) selecionada(s)?',
        headerNameStarted: 'Começou às',
        headerNameSubmitted: 'Enviado às',
        headerNameResponseStatus: 'Status da resposta',
        responseStatusCompleted: 'Completa',
        responseStatusPartial: 'Parcial',
        responseStatusTest: 'Teste'
      },
      theResultsNavbar: {
        getAnOverview: 'Obtenha uma visão geral do desempenho e das respostas que seu formulário recebeu.',
        summary: 'Resumo',
        insightsAutomagically: 'Insights gerados automagicamente para você.',
        insights: 'Insights',
        seeAllResponses: 'Ver todas as respostas em uma tabela.',
        responses: 'Respostas',
        seeInbox: 'Ver respostas individualmente.',
        inbox: 'Caixa de entrada'
      }
    },
    formShare: {
      embedSettings: {
        embedMode: 'Modo de Incorporação',
        design: 'Design',
        hiddenFields: 'Campos ocultos',
        utmParameters: 'Parâmetros UTM'
      },
      modalPreview: {
        desktop: 'Desktop',
        mobile: 'Mobile',
        reload: 'Recarregar'
      },
      modalEmbed: {
        customize: 'Personalize e incorpore em seu website',
        copyAndPaste: 'Copie e cole o código abaixo no corpo da sua página, na seção em que você deseja que ele seja exibido.',
        copyToClipboard: 'Copiar para área de transferência',
        copyEmbedCode: 'Copiar código',
        notificationSuccess: 'Código copiado para a área de transferência!',
        notificationError: 'Seu navegador não suporta a função de copiar para a área de transferência.'
      },
      modalWordPress: {
        title: 'Instale nosso plug-in do WordPress',
        step1: 'Faça o download do plug-in Yay! Forms para WordPress',
        step2: '2. No painel do WordPress, escolha Plugins > Adicionar novo (Add New).',
        step3: '3. Clique em Upload Plugin na parte superior da página.',
        step4: '4. Clique em Choose File (Escolher arquivo) e localize o plug-in.',
        step5: '5. Após a conclusão da instalação, clique em Activate Plugin (Ativar plug-in).'
      },
      modalQrcode: {
        title: 'Salve seu QR code',
        pointYourCamera: 'Aponte sua câmera para este QR code para abrir este formulário. Você pode baixar este QR code para promover o seu formulário em qualquer mídia.',
        cancel: 'Cancelar',
        downloadQrCode: 'Baixar QR code'
      },
      theHiddenFields: {
        title: 'Campos ocultos',
        addMyParameter: 'Adicione qualquer parâmetro, como um e-mail ou um ID personalizado, para ajudá-lo a rastrear quem completou o formulário. Após adicionar um campo oculto, ele pode ser enviado via query string, como no exemplo a seguir:',
        deleteHiddenField: 'Excluir campo oculto',
        addHiddenField: '+ Adicionar campo oculto...',
        matches: 'Somente letras, números, traços e sublinhados são permitidos.',
        validateHiddenField: 'O nome que você atribuiu ao campo oculto já está em uso ou precisa ser habilitado por meio de uma integração adequada.',
        cancel: 'Cancelar'
      },
      theUtmParameters: {
        title: 'Parâmetros UTM',
        description: 'Adicione os parâmetros UTM (utm_source, utm_medium, utm_campaign, utm_term, utm_content) à URL do seu formulário para que você possa rastrear seus usuários.'
      }
    },
    forms: {
      modalMoveForm: {
        title: 'Mover formulário',
        save: 'Salvar',
        cancel: 'Cancelar',
        success: 'Formulário movido com sucesso!',
        noWorkspacesAvailable: 'Não há workspaces disponíveis para mover este formulário.'
      },
      formListItem: {
        duplicateDelete: 'Duplicar, deletar...',
        duplicate: 'Duplicar',
        move: 'Mover',
        export: 'Exportar',
        delete: 'Excluir...',
        noResponsesYet: 'Sem respostas',
        live: 'Publicado',
        scheduled: 'agendado',
        creating: 'criando...',
        areYouSureYouWant: 'Tem certeza de que deseja excluir o formulário ',
        andAll: 'e tudo',
        responses: ' respostas',
        notificationSuccess: 'URL copiada para a área de transferência!',
        notificationError: 'Seu navegador não suporta copiar para a área de transferência.',
        bySharing: 'Ao compartilhar a seguinte URL com outros usuários do Yay! Forms, você permite que eles importem uma cópia deste formulário para suas contas: ',
        yourFormHasBeen: 'Seu formulário foi exportado!',
        copyImport: 'Copiar link de importação',
        brainstormingIdeas: 'Ideias para brainstorming...',
        addingQuestions: 'Adicionando questões...',
        puttingFinishing: 'Dando os toques finais...'
      },
      modalNewForm: {
        createForm: 'Criar um formulário...',
        tooltipIa: 'Apenas nos informe o objetivo do seu formulário e deixe a Inteligência Artificial fazer o trabalho pesado para você.',
        automagically: 'Automagicamente por IA',
        tooltipCreateForm: 'Crie um formulário com suas próprias mãos do zero.',
        fromScratch: 'Do zero',
        whatIsThe: 'Qual é o propósito ',
        specific: 'específico',
        purposeOfThisForm: 'deste formulário?',
        placeholder: 'Escreva o que você deseja realizar com este formulário.',
        pleaseDescribe: 'Por favor, descreva em linguagem natural o que você deseja alcançar com este formulário.',
        createFromWith: 'Criar formulário com assistência de IA',
        untitledForm: 'formulário sem título',
        notificationSuccess: 'Estamos criando o seu formulário, por favor, aguarde alguns segundos...',
        words: {
          captureLeads: 'Capturar leads para o curso de idiomas que eu ensino',
          imManager: 'Eu sou um gerente que deseja capturar feedback dos membros da minha equipe',
          understandTheNeeds: 'Compreender as necessidades dos clientes de uma empresa de seguros',
          knowMyCustomers: 'Saber o que meus clientes pensam sobre os serviços da minha agência de marketing',
          performEmployee: 'Realizar uma pesquisa de satisfação dos funcionários',
          collectFeedback: 'Coletar feedback dos clientes do curso que estou ministrando',
          knowThePains: 'Conhecer as dificuldades dos clientes do meu restaurante de pizza'
        }
      },
      theFormNavBar: {
        goBackToWorkspace: 'Volte para o espaço de trabalho',
        emptyValuePlaceholder: 'Nota sem título',
        editPlaceholder: 'Qual é o título?',
        edit: 'Editar',
        integrate: 'Integrar',
        share: 'Compartilhar',
        results: 'Resultados',
        discardChanges: 'Descartar mudanças',
        discard: 'Descartar',
        saveChangesAndTurn: 'Salvar alterações e tornar este formulário público',
        publish: 'Publicar',
        title: 'formulário sem título'
      }
    },
    layout: {
      theNavbar: {
        title: 'Home'
      },
      theNavbarAlert: {
        yourAccount: 'Sua conta está suspensa. Por favor, faça um pagamento para reativar sua conta.',
        makePayment: 'Faça um pagamento'
      },
      theUpgradePlanButton: {
        responsesUsed: ' respostas utilizadas neste mês em seu plano ',
        plan: '',
        responses: 'Respostas',
        upgrade: 'Upgrade',
        redirecting: 'Redirecionando...'
      },
      theUserAvatarDropdown: {
        profile: 'Perfil',
        earnMoney: 'Programa de afiliados',
        billing: 'Cobrança',
        redirecting: 'Redirecionando...',
        support: 'Suporte',
        signOut: 'Sair'
      },
      theUserAvatarList: {
        show: 'Mostrar ',
        more: ' Mais',
        membersOf: 'Membros de ',
        removeFromWorkspace: 'Remover do espaço de trabalho...',
        addUserTo: 'Adicionar usuário a ',
        inviteUserTo: 'Convidar usuário para ',
        sendingInvitation: 'Enviando convite...',
        invite: 'Convidar',
        label: 'Email',
        placeholder: 'E-mail do usuário que você deseja convidar',
        userRemoved: 'Usuário removido do espaço de trabalho!',
        invitationSent: 'Convite enviado!',
        title: 'Remover Usuário',
        message: 'não terá mais acesso a este espaço de trabalho.',
        noButtonText: 'Cancelar',
        yesButtonText: 'Sim, Remover'
      },
      theWorkspaceSubdomain: {
        subdomain: 'Subdomínio'
      },
      theWorkspaceOptions: {
        workspaceOptionsTooltip: 'Opções do espaço de trabalho...',
        workspaceOptions: 'Opções do espaço de trabalho',
        rename: 'Renomear',
        delete: 'Excluir',
        title: 'Espere um minuto!',
        goingAhead: 'Continuar significa que você não poderá acessar os formulários de ',
        anymore: ' Não poderá mais acessar. Tem certeza de que deseja sair deste espaço de trabalho?',
        noButtonText: 'Não',
        yesButtonText: 'Sim, sair do espaço de trabalho'
      },
      theWorkspaceDropdown: {
        addWorkspace: 'Adicionar espaço de trabalho'
      },
      theNotificationsDropdown: {
        title: 'Notificações',
        onboardingTitle: 'Primeiros Passos',
        markAllAsRead: 'Marcar todas como lidas',
        noNotifications: 'Não há notificações',
        noMoreNotifications: 'Não há mais notificações',
        markAsRead: 'Marcar como lida',
        markAsUnread: 'Marcar como não lida'
      }
    },
    workspace: {
      modalAddWorkspace: {
        title: 'Novo espaço de trabalho',
        workspaceName: 'Nome do espaço de trabalho',
        workspaceNamePlaceholder: 'Nome do espaço de trabalho...',
        cancel: 'Cancelar',
        save: 'salvar'
      },
      modalDeleteWorkspace: {
        title: 'Excluir espaço de trabalho',
        allForms: 'Todas as formas deste espaço de trabalho serão excluídas. Digite ',
        toContinue: ' para continuar.',
        workspaceName: 'Nome do espaço de trabalho',
        workspaceNamePlaceholder: 'Nome do espaço de trabalho...',
        validationMessages: 'O nome do espaço de trabalho não corresponde.',
        cancel: 'Cancelar',
        deleteTheWorkspace: 'Excluir o espaço de trabalho e todas as suas formas'
      },
      modalRenameWorkspace: {
        title: 'Renomear espaço de trabalho',
        workspaceName: 'Nome do espaço de trabalho',
        placeHolderNewWorkspaceName: 'Novo nome do espaço de trabalho...',
        cancel: 'Cancelar',
        rename: 'Renomear'
      },
      modalChangeSubdomain: {
        title: 'Alterar subdomínio',
        subdomain: 'Subdomínio',
        placeHolderSubdomain: 'minhaempresa (texto antes de .yayforms.link)',
        help: 'O subdomínio deve ser único e só pode conter letras ou números. Ex. <strong>empresa</strong> em <strong>empresa</strong>.yayforms.link',
        cancel: 'Cancelar',
        change: 'Alterar'
      }
    }
  },
  lib: {
    utils: {
      field: {
        multipleChoice: 'Múltipla escolha',
        pictureChoice: 'Escolha de imagem',
        opinionScale: 'Escala de opinião',
        yesNo: 'Sim/Não',
        nps: 'Net Promoter Score®',
        dropdown: 'Menu suspenso',
        rating: 'Avaliação',
        email: 'Email',
        phone: 'Telefone',
        date: 'Data',
        website: 'Website',
        shortText: 'Texto curto',
        longText: 'Texto Longo',
        number: 'Número',
        statement: 'Declaração',
        fileUpload: 'Upload de arquivo',
        welcomeScreen: 'Tela de boas vindas',
        endingScreen: 'Tela Final',
        calendly: 'Calendly',
        choices: 'Escolhas',
        contactInfo: 'Informações de contato',
        textNumber: 'Texto e número',
        formStructure: 'Estrutura do formulário',
        file: 'Arquivo',
        integrations: 'Integrações'
      },
      logic: {
        jumpTo: 'Ir para',
        add: 'Somar',
        subtract: 'Subtrair',
        multiply: 'Multiplicar',
        divide: 'Dividir',
        by: 'por',
        from: 'de',
        to: 'para',
        is: 'é',
        isNot: 'não é',
        isGreater: 'é maior que',
        isLess: 'é menor que',
        isGreaterOrEqual: 'é maior que ou igual a',
        isLessOrEqual: 'é menor ou igual a',
        contains: 'contém',
        doesntContain: 'não contém',
        startsWith: 'começa com',
        endsWith: 'termina com',
        isEmpty: 'está vazio',
        isNotEmpty: 'não está vazio',
        isIn: 'é em',
        isNotIn: 'não é em',
        isBetween: 'está entre',
        isNotBetween: 'não está entre',
        isToday: 'é hoje',
        isYesterday: 'é ontem',
        isTomorrow: 'é amanhã',
        isAfter: 'é depois',
        isBefore: 'é antes',
        isTheGreatest: 'tem o maior valor',
        isTheLeast: 'tem o menor valor'
      },
      theme: {
        fields: 'Títulos e textos',
        answers: 'Respostas',
        buttons: 'Fundo',
        buttonText: 'Texto',
        Background: 'Fundo',
        Font: 'Fonte'
      },
      i18n: {
        en: 'Inglês',
        es: 'Espanhol',
        pt: 'Português (Brasil)'
      }
    }
  },
  AG_GRID_LOCALE: {
    selectAll: '(Selecionar tudo)',
    selectAllSearchResults: '(Selecionar todos os resultados da pesquisa)',
    searchOoo: 'Procurar...',
    blanks: '(Espaços em branco)',
    noMatches: 'Sem combinações',
    filterOoo: 'Filtrar...',
    equals: 'É igual a',
    notEqual: 'Diferente',
    blank: 'Espaço em branco',
    notBlank: 'Não está em branco',
    empty: 'Escolha um',
    lessThan: 'Menor que',
    greaterThan: 'Maior que',
    lessThanOrEqual: 'Menor que ou igual',
    greaterThanOrEqual: 'Maior que ou igual',
    inRange: 'Dentro do alcance',
    inRangeStart: 'de',
    inRangeEnd: 'para',
    contains: 'contém',
    notContains: 'Não contém',
    startsWith: 'Começa com',
    endsWith: 'Termina com',
    dateFormatOoo: 'yyyy-mm-dd',
    andCondition: 'E',
    orCondition: 'OU',
    applyFilter: 'Aplicar',
    resetFilter: 'Reiniciar',
    clearFilter: 'Claro',
    cancelFilter: 'Cancelar',
    textFilter: 'Filtro de texto',
    numberFilter: 'Filtro de número',
    dateFilter: 'Filtro de Data',
    setFilter: 'Definir filtro',
    groupFilterSelect: 'Selecione o campo:',
    columns: 'Colunas',
    filters: 'Filtros',
    pivotMode: 'Modo Pivot',
    groups: 'Grupos de linhas',
    rowGroupColumnsEmptyMessage: 'Arraste aqui para definir grupos de linhas',
    values: 'Valores',
    valueColumnsEmptyMessage: 'Arraste aqui para agregar',
    pivots: 'Rótulos de coluna',
    pivotColumnsEmptyMessage: 'Arraste aqui para definir os rótulos das colunas',
    group: 'Grupo',
    rowDragRow: 'Linha',
    rowDragRows: 'Linhas',
    loadingOoo: 'Carregando...',
    loadingError: 'ERR',
    noRowsToShow: 'Nenhuma linha para mostrar',
    enabled: 'Habilitado',
    pinColumn: 'Fixar coluna',
    pinLeft: 'Fixar à Esquerda',
    pinRight: 'Fixar à direita',
    noPin: 'sem alfinete',
    valueAggregation: 'Agregação de valor',
    noAggregation: 'Nenhum',
    autosizeThiscolumn: 'Dimensionar automaticamente esta coluna',
    autosizeAllColumns: 'Dimensionar automaticamente todas as colunas',
    groupBy: 'Agrupar por',
    ungroupBy: 'Desagrupar por',
    resetColumns: 'Resetar Colunas',
    expandAll: 'Expandir todos os grupos de linhas',
    collapseAll: 'Fechar todos os grupos de linhas',
    copy: 'Copiar',
    ctrlC: 'Ctrl+C',
    ctrlX: 'Ctrl+X',
    copyWithHeaders: 'Copiar com cabeçalhos',
    copyWithGroupHeaders: 'Copiar com cabeçalhos de grupo',
    cut: 'Cortar',
    paste: 'Copiar',
    ctrlV: 'Ctrl+V',
    export: 'Exportar',
    csvExport: 'Exportação CSV',
    excelExport: 'Exportar Excel',
    sum: 'Soma',
    first: 'Primeiro',
    last: 'Último',
    min: 'Min',
    max: 'Max',
    none: 'Nenhum',
    count: 'Contar',
    avg: 'Média',
    filteredRows: 'Filtrado',
    selectedRows: 'Selecionado',
    totalRows: 'Total de Linhas',
    totalAndFilteredRows: 'Linhas',
    more: 'Mais',
    to: 'para',
    of: 'De',
    page: 'Página',
    pageLastRowUnknown: '?',
    nextPage: 'Próxima página',
    lastPage: 'Última página',
    firstPage: 'Primeira página',
    previousPage: 'Página anterior',
    pivotColumnGroupTotals: 'Total',
    pivotChartAndPivotMode: 'Gráfico dinâmico e modo dinâmico',
    pivotChart: 'Gráfico Dinâmico',
    chartRange: 'Intervalo do gráfico',
    columnChart: 'Coluna',
    groupedColumn: 'Agrupados',
    stackedColumn: 'Empilhado',
    normalizedColumn: '100% empilhado',
    barChart: 'Barra',
    groupedBar: 'Agrupados',
    stackedBar: 'Empilhado',
    normalizedBar: '100% empilhado',
    pieChart: 'Torta',
    pie: 'Torta',
    doughnut: 'Rosquinha',
    line: 'Linha',
    xyChart: 'X Y (Dispersão)',
    scatter: 'Dispersão',
    bubble: 'Bolha',
    areaChart: 'Área',
    area: 'Área',
    stackedArea: 'Empilhado',
    normalizedArea: '100% empilhado',
    histogramChart: 'Histograma',
    histogramFrequency: 'Frequência',
    combinationChart: 'Combinação',
    columnLineCombo: 'Coluna & Linha',
    AreaColumnCombo: 'Área & Coluna',
    ariaChecked: 'verificado',
    ariaColumn: 'Coluna',
    ariaColumnGroup: 'Grupo de colunas',
    ariaColumnList: 'Lista de Colunas',
    ariaColumnSelectAll: 'Alternar seleção de todas as colunas',
    ariaDateFilterInput: 'Entrada do filtro de data',
    ariaDefaultListName: 'Lista',
    ariaFilterColumnsInput: 'Entrada de colunas de filtro',
    ariaFilterFromValue: 'Filtrar do valor',
    ariaFilterInput: 'Filtrar Entrada',
    ariaFilterList: 'Filtrar Lista',
    ariaFilterToValue: 'Filtrar pelo valor',
    ariaFilterValue: 'Filtrar Valor',
    ariaFilterMenuOpen: 'Abrir menu de filtro',
    ariaFilteringOperator: 'Operador de filtragem',
    ariaHidden: 'escondido',
    ariaIndeterminate: 'indeterminado',
    ariaInputEditor: 'Editor de entrada',
    ariaMenuColumn: 'Pressione CTRL ENTER para abrir o menu da coluna.',
    ariaRowDeselect: 'Pressione ESPAÇO para desmarcar esta linha',
    ariaRowSelectAll: 'Pressione espaço para alternar a seleção de todas as linhas',
    ariaRowToggleSelection: 'Pressione espaço para alternar a seleção de linha',
    ariaRowSelect: 'Pressione ESPAÇO para selecionar esta linha',
    ariaSearch: 'Pesquisar',
    ariaSortableColumn: 'Pressione ENTER para ordenar',
    ariaToggleVisibility: 'Pressione ESPAÇO para alternar a visibilidade',
    ariaToggleCellValue: 'Pressione ESPAÇO para alternar o valor da célula',
    ariaUnchecked: 'desmarcado',
    ariaVisible: 'visivel',
    ariaSearchFilterValues: 'Pesquisar valores de filtro',
    ariaRowGroupDropZonePanelLabel: 'Grupos de linhas',
    ariaValuesDropZonePanelLabel: 'Valores',
    ariaPivotDropZonePanelLabel: 'Rótulos de coluna',
    ariaDropZoneColumnComponentDescription: 'Pressione DELETE para remover',
    ariaDropZoneColumnValueItemDescription: 'Pressione ENTER para alterar o tipo de agregação',
    ariaDropZoneColumnGroupItemDescription: 'Pressione ENTER para ordenar',
    ariaDropZoneColumnComponentAggFuncSeperator: ' de ',
    ariaDropZoneColumnComponentSortAscending: 'ascendente',
    ariaDropZoneColumnComponentSortDescending: 'descendente',
    ariaLabelColumnMenu: 'Menu da Coluna',
    ariaLabelCellEditor: 'Editor de células',
    ariaLabelDialog: 'Diálogo',
    ariaLabelSelectField: 'Selecione o campo',
    ariaLabelTooltip: 'Dica de ferramenta',
    ariaLabelContextMenu: 'Menu contextual',
    ariaLabelSubMenu: 'SubMenu',
    ariaLabelAggregationFunction: 'Função de agregação',
    thousandSeparator: ',',
    decimalSeparator: '.',
    true: 'Verdadeiro',
    false: 'Falso',
    invalidDate: 'Data inválida',
    invalidNumber: 'Número inválido',
    january: 'Janeiro',
    february: 'Fevereiro',
    march: 'março',
    april: 'Abril',
    may: 'Maio',
    june: 'Junho',
    july: 'Julho',
    august: 'Agosto',
    september: 'Setembro',
    october: 'Outubro',
    november: 'Novembro',
    december: 'Dezembro'
  }
}
