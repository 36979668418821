import { removeHtmlTags } from './string.utils'

export const operators = [
  { label: 'lib.utils.logic.is', name: 'eq', symbol: '=' },
  { label: 'lib.utils.logic.isNot', name: 'notEq', symbol: '≠' },
  { label: 'lib.utils.logic.isGreater', name: 'gt', symbol: '>' },
  { label: 'lib.utils.logic.isLess', name: 'lt', symbol: '<' },
  { label: 'lib.utils.logic.isGreaterOrEqual', name: 'gte', symbol: '≥' },
  { label: 'lib.utils.logic.isLessOrEqual', name: 'lte', symbol: '≤' },
  { label: 'lib.utils.logic.isTheGreatest', name: 'isTheGreatest' },
  { label: 'lib.utils.logic.isTheLeast', name: 'isTheLeast' },
  { label: 'lib.utils.logic.contains', name: 'includes' },
  { label: 'lib.utils.logic.doesntContain', name: 'notIncludes' },
  { label: 'lib.utils.logic.startsWith', name: 'startsWith' },
  { label: 'lib.utils.logic.endsWith', name: 'endsWith' },
  { label: 'lib.utils.logic.isEmpty', name: 'isEmpty' },
  { label: 'lib.utils.logic.isNotEmpty', name: 'isNotEmpty' },
  { label: 'lib.utils.logic.isIn', name: 'isIn' },
  { label: 'lib.utils.logic.isNotIn', name: 'isNotIn' },
  { label: 'lib.utils.logic.isBetween', name: 'isBetween' },
  { label: 'lib.utils.logic.isNotBetween', name: 'isNotBetween' },
  { label: 'lib.utils.logic.isToday', name: 'isToday' },
  { label: 'lib.utils.logic.isYesterday', name: 'isYesterday' },
  { label: 'lib.utils.logic.isTomorrow', name: 'isTomorrow' },
  { label: 'lib.utils.logic.isAfter', name: 'isAfter' },
  { label: 'lib.utils.logic.isBefore', name: 'isBefore' }
]

const fieldOperatorsMap = {
  'welcome-screen': [],
  'ending-screen': [],
  'multiple-choice': ['eq', 'notEq', 'includes', 'notIncludes', 'startsWith', 'endsWith', 'isEmpty', 'isNotEmpty'],
  'picture-choice': ['eq', 'notEq', 'includes', 'notIncludes', 'startsWith', 'endsWith', 'isEmpty', 'isNotEmpty'],
  'opinion-scale': ['eq', 'notEq', 'gt', 'lt'],
  nps: ['eq', 'notEq', 'gt', 'lt'],
  rating: ['eq', 'notEq', 'gt', 'lt'],
  'short-text': ['eq', 'notEq', 'includes', 'notIncludes', 'startsWith', 'endsWith', 'isEmpty', 'isNotEmpty'],
  'long-text': ['eq', 'notEq', 'includes', 'notIncludes', 'startsWith', 'endsWith', 'isEmpty', 'isNotEmpty'],
  'yes-no': ['eq'],
  email: ['eq', 'notEq', 'includes', 'notIncludes', 'startsWith', 'endsWith', 'isEmpty', 'isNotEmpty'],
  calendly: ['isEmpty', 'isNotEmpty'],
  phone: ['eq', 'notEq', 'includes', 'notIncludes', 'startsWith', 'endsWith', 'isEmpty', 'isNotEmpty'],
  date: ['isEmpty', 'isNotEmpty'], // ['eq', 'notEq', 'isEmpty', 'isNotEmpty', 'isToday', 'isYesterday', 'isTomorrow', 'isAfter', 'isBefore', 'isBetween', 'isNotBetween'],
  statement: ['eq'],
  website: ['eq', 'notEq', 'includes', 'notIncludes', 'startsWith', 'endsWith', 'isEmpty', 'isNotEmpty'],
  dropdown: ['eq', 'notEq', 'includes', 'notIncludes', 'startsWith', 'endsWith', 'isEmpty', 'isNotEmpty'],
  'file-upload': ['isEmpty', 'isNotEmpty'],
  number: ['eq', 'notEq', 'gt', 'lt', 'gte', 'lte', 'isEmpty', 'isNotEmpty']
  // ranking: [],
  // 'picture-choice': [],
  // matrix: [],
  // legal: [],
  // payment: [],
}

const variableOperatorsMap = {
  text: ['eq', 'notEq', 'includes', 'notIncludes', 'startsWith', 'endsWith', 'isEmpty', 'isNotEmpty'],
  number: ['eq', 'notEq', 'gt', 'lt', 'gte', 'lte', 'isTheGreatest', 'isTheLeast'],
  boolean: ['eq']
}

export const actions = [
  { key: 'jumpTo', label: 'lib.utils.logic.jumpTo', icon: 'oi-move-to-bottom' },
  { key: 'add', label: 'lib.utils.logic.add', verb: 'lib.utils.logic.to', icon: 'oi-add-line' },
  { key: 'subtract', label: 'lib.utils.logic.subtract', verb: 'lib.utils.logic.from', icon: 'oi-subtract-line' },
  { key: 'multiply', label: 'lib.utils.logic.multiply', verb: 'lib.utils.logic.by', icon: 'oi-close-line' },
  { key: 'divide', label: 'lib.utils.logic.divide', verb: 'lib.utils.logic.by', icon: 'oi-divide-line' }
]

export const argumentTypes = {
  field: 'field',
  variable: 'variable',
  hiddenField: 'hiddenField',
  tracking: 'tracking'
}

function conditionArgumentField (field) {
  function getDefaultValue (field) {
    if (field.type === 'multiple-choice') {
      return removeHtmlTags(field.options[0])
    }
    if (field.type === 'picture-choice') {
      return removeHtmlTags(field.options[0].title)
    }
    if (field.type === 'dropdown') {
      return removeHtmlTags(field.options[0])
    }
    if (field.type === 'yes-no') {
      return 'Yes'
    }
    if (field.type === 'opinion-scale') {
      return field.options[0]
    }
    if (field.type === 'nps') {
      return field.options[0]
    }
    if (field.type === 'rating') {
      return field.options[0]
    }

    return ''
  }

  function getDefaultOperator () {
    const fieldOperators = fieldOperatorsMap[field.type]

    if (fieldOperators.length === 0) { throw new Error('This field type has no operators') }

    return fieldOperators[0]
  }

  function emptyCondition (operator) {
    const fieldId = field.id
    const value = getDefaultValue(field)
    const variableName = 'score'
    operator = operator || getDefaultOperator(field.type)

    return field.type === 'statement'
      ? { variableName, operator, value }
      : { fieldId, operator, value }
  }

  function getOperators () {
    return fieldOperatorsMap[field.type].map(operator => operators.find(o => o.name === operator))
  }

  return { emptyCondition, getOperators }
}

function conditionArgumentVariable (variable) {
  function getDefaultValue () {
    if (variable.type === 'number') {
      return 0
    }
    if (variable.type === 'text') {
      return ''
    }
    if (variable.type === 'boolean') {
      return true
    }

    return ''
  }

  function getDefaultOperator () {
    const variableOperators = variableOperatorsMap[variable.type]

    if (variableOperators.length === 0) { throw new Error('This variable type has no operators') }

    return variableOperators[0]
  }

  function emptyCondition (operator) {
    const variableName = variable.name
    const value = getDefaultValue()
    operator = operator || getDefaultOperator(variable.type)

    return { variableName, operator, value }
  }

  function getOperators () {
    return variableOperatorsMap[variable.type].map(operator => operators.find(o => o.name === operator))
  }

  return { emptyCondition, getOperators }
}

/**
 * It returns the if condition argument object
 * @param {string} argumentType
 * @param {*} value The object field, variable, hiddenField or tracking
 * @returns {object} The condition argument object
 */
export function conditionArgument (argumentType, argument) {
  if (argumentType === 'field') {
    return conditionArgumentField(argument)
  }
  if (argumentType === 'variable') {
    return conditionArgumentVariable(argument)
  }
}

export function guessConditionArgumentType (condition) {
  if (condition.fieldId) {
    return argumentTypes.field
  }
  if (condition.variableName) {
    return argumentTypes.variable
  }
  if (condition.hiddenFieldId) {
    return argumentTypes.hiddenField
  }
  if (condition.tracking) {
    return argumentTypes.tracking
  }
  throw new Error('Unknown condition argument type')
}

export function isMathOperation (key) {
  return 'add,subtract,multiply,divide'.includes(key)
}

export function actionLabel (key) {
  return actions.find(action => action.key === key).label
}

export function actionVerb (key) {
  return actions.find(action => action.key === key).verb
}

export function actionIcon (key) {
  return actions.find(action => action.key === key).icon
}

export function operatorLabel (operatorName) {
  return operators.find(o => o.name === operatorName).label
}

export function operatorSymbol (operatorName) {
  return operators.find(o => o.name === operatorName).symbol
}

export function operatorSymbolOrLabel (operatorName) {
  const o = operators.find(o => o.name === operatorName)
  return o.symbol || o.label
}

export function getDefaultJumpToAction (jumpToField) {
  const value = jumpToField?.id || getDefaultEndingScreen().id
  return { function: 'jumpTo', value }
}

export function getOptionValue (option) {
  // field.options["item 1", "item 2"]
  // field.options[{ title: "", url: "", alt: "" }]
  if (typeof option === 'object') {
    return option.title
  }

  return option
}

export function optionsIncludes (options, value) {
  if (typeof options[0] === 'object') {
    return options.find(o => o.title === value) !== undefined
  }

  return options.includes(value)
}

export function getDefaultEndingScreen () {
  return {
    id: 'default-ending-screen',
    type: 'ending-screen',
    position: 0,
    title: 'Yay! You completed it!'
  }
}

export function emptyRule (field, jumpToField) {
  const defaultCondition = conditionArgumentField(field).emptyCondition()
  const defaultAction = getDefaultJumpToAction(jumpToField)

  return {
    logicalOperator: 'or',
    conditions: [defaultCondition],
    actions: [defaultAction]
  }
}
